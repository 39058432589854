<template>
  <div class="px-8 mt-4">
    <v-card>
      <v-card-title class="text-body-2 white lighten-2 grey">
        {{ useInstructionTitle }}
      </v-card-title>

      <v-card-text
        class="py-2"
        id="noticeContent"
        ref="noticeContent"
        style="font-size:14px"
      >
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "MobileStaticNotice",
  props: [
    "fontColor",
    "showUseInstruction",
    "noticeDisplay",
    "useInstructionTitle",
    "useInstruction"
  ],
  mounted() {
    this.$refs.noticeContent.innerHTML = this.useInstruction;
  }
};
</script>

<style lang="less" scoped>
/deep/ .v-dialog {
  margin-top: 0px !important;
}

/deep/ #noticeContent {
  pre {
    white-space: pre-wrap; /*css-3*/
    white-space: -moz-pre-wrap; /*Mozilla,since1999*/
    white-space: -pre-wrap; /*Opera4-6*/
    white-space: -o-pre-wrap; /*Opera7*/
    word-wrap: break-word; /*InternetExplorer5.5+*/
  }
}
</style>
