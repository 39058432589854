<template>
  <div style="height: 270px;">
    <div class="d-flex flex-column align-center" style="padding:6px 0">
      <v-sheet
        color="white"
        elevation="2"
        height="100"
        width="100"
        class="rounded-circle"
      >
        <v-img
          contain
          width="100"
          height="100"
          class="rounded-circle"
          :src="headimgurl ? headimgurl : require('@/assets/avatar.png')"
        ></v-img>
      </v-sheet>
      <div class="text-h6 mt-6 text-center" :style="{ color: fontColor }">
        {{ nickname }}
      </div>
      <div
        class="mt-12 text-body-1 text-center mt-4"
        :style="{ color: fontColor }"
      >
        <div>你即将绑定此微信账号</div>
        <div>后续可以使用此微信账号登录</div>
      </div>
    </div>

    <div
      class="text-body-2 d-flex justify-space-around align-center"
      style="padding:10px 0"
    >
      <v-btn
        @click="cancelBindEvent"
        min-height="40"
        class="white--text grey lighten-1"
        style="color:#707B9A;"
      >
        取消绑定
      </v-btn>
      <v-btn
        @click="doBindEvent"
        :style="{
          backgroundColor: highlightColor,
          color: fontColor
        }"
        min-height="40"
        class="white--text"
      >
        绑定并继续
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "WechatBind",
  props: ["headimgurl", "nickname", "sonData"],
  data() {
    return {
      highlightColor: "",
      fontColor: ""
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    // 绑定并继续
    doBindEvent() {
      this.$emit("doBind");
    },
    // 取消绑定
    cancelBindEvent() {
      this.$emit("cancelBind");
    },
    fetchData() {
      let {
        loginFrame: { loginFontColor, loginHighLight }
      } = this.sonData;

      this.highlightColor = loginHighLight;
      this.fontColor = loginFontColor;
    }
  },
  watch: {
    sonData() {
      this.fetchData();
    }
  }
};
</script>
