<template>
  <div v-if="showLoginName">
    <div
      class="text-lg-h6 text-md-h6 text-sm-h6 text-xs-h6 darken-3 mb-4 text-center"
    >
      {{ curTabItem["name"] }}
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginModeTitle",
  props: ["curTabItem", "showLoginName"]
};
</script>

<style lang="less" scoped>
/deep/ #noticeContent {
  pre {
    white-space: pre-wrap; /*css-3*/
    white-space: -moz-pre-wrap; /*Mozilla,since1999*/
    white-space: -pre-wrap; /*Opera4-6*/
    white-space: -o-pre-wrap; /*Opera7*/
    word-wrap: break-word; /*InternetExplorer5.5+*/
  }
}
</style>
