<template>
  <div>
    <div
      v-if="otherLogins.length > 0 && tabSources.length != 0"
      class="d-flex align-center px-16 mt-4"
    >
      <div style="border-top:1px solid #D1D6DB;height:1px;flex:3"></div>
      <div class="text-caption mx-2">
        其他登录方式
      </div>
      <div style="border-top:1px solid #D1D6DB;height:1px;flex:3"></div>
    </div>
    <!-- link登陆方式 -->
    <div
      v-if="otherLogins.length > 0"
      class="d-flex align-center justify-center flex-wrap mt-3"
      style="margin:0 auto;"
    >
      <div v-for="link in otherLogins" :key="link.id">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-img
              style="cursor:pointer"
              v-bind="attrs"
              v-on="on"
              class="rounded mx-4 my-2"
              :src="require('@/assets/sources/' + link.type + '.png')"
              width="44"
              @click="goLink(link)"
            ></v-img>
          </template>
          <span>{{ link.name }}</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OtherLink",
  props: ["otherLogins", "tabSources"],
  methods: {
    // 点击其他登陆方式
    goLink(data) {
      location.href = data.link;
    }
  }
};
</script>
