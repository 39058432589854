<template>
  <div
    v-if="noticeDisplay === 'staticState' && showUseInstruction"
    style="flex:1"
  >
    <div
      class="white--text pa-6"
      :style="{
        position: 'relative',
        minWidth: '300px',
        height: '500px',
        overflow: 'auto',
        borderRadius: '6px',
        marginRight: loginSite === 'left' ? '0' : '20px',
        marginLeft: loginSite === 'left' ? '20px' : '0',
        backgroundColor: `rgba(${loginBgColor},${loginBgTransparency})`
      }"
    >
      <div
        class="d-flex justify-center"
        :style="{ color: fontColor, fontSize: '16px', fontWeight: '600' }"
      >
        {{ noticeTitle }}
      </div>
      <v-divider style="border-color:#fff"></v-divider>
      <div
        id="noticeContent"
        class="pa-4"
        :style="{ color: fontColor, fontSize: '14px' }"
      ></div>
    </div>
  </div>
</template>

<script>
import { HexToRgb } from "@/util/util";
export default {
  name: "Notice",
  props: ["sonData"],
  data() {
    let isFull = value => {
      let isfull = value ? true : false;
      return isfull;
    };

    return {
      rules: {
        isFull: [v => isFull(v) || "此项为必填项"]
      },
      show: false,
      form: {},
      loginData: {},
      showUseInstruction: false,
      highlightColor: "",
      loginBgColor: "",
      loginBgTransparency: 0.5,
      fontColor: "",
      noticeTitle: "",
      loginSite: "",
      noticeDisplay: ""
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      let {
        noticeDisplay,
        showUseInstruction,
        useInstruction,
        useInstructionTitle,
        loginFrame: { loginFontColor, loginBgColor, loginBgOpcity, loginSite }
      } = this.sonData;

      this.noticeDisplay = noticeDisplay;
      this.showUseInstruction = showUseInstruction;
      this.noticeTitle = useInstructionTitle;
      if (useInstruction) {
        this.$nextTick(() => {
          document.getElementById("noticeContent").innerHTML = useInstruction;
        });
      }
      this.loginBgColor = HexToRgb(loginBgColor);
      this.loginBgTransparency = loginBgOpcity ? loginBgOpcity : 0.5;
      this.fontColor = loginFontColor;
      this.loginSite = loginSite;
    }
  },
  watch: {
    curItem() {
      this.fetchData();
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ #noticeContent {
  pre {
    white-space: pre-wrap; /*css-3*/
    white-space: -moz-pre-wrap; /*Mozilla,since1999*/
    white-space: -pre-wrap; /*Opera4-6*/
    white-space: -o-pre-wrap; /*Opera7*/
    word-wrap: break-word; /*InternetExplorer5.5+*/
  }
}
</style>
