<template>
  <div
    v-if="footContent"
    class="text-center text-caption py-2 unselect"
    :style="{
      position: 'fixed',
      bottom: 0,
      width: '100%',
      backgroundColor: `rgba(${loginBgColor},${loginBgTransparency})`,
      color: fontColor
    }"
  >
    {{ footContent }}
  </div>
</template>

<script>
import { HexToRgb } from "@/util/util";

export default {
  name: "FootDisplay",
  props: ["sonData", "footContent"],
  data() {
    return {
      loginBgColor: "",
      fontColor: "",
      loginBgTransparency: ""
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      let {
        loginFrame: { loginFontColor, loginBgColor, loginBgOpcity }
      } = this.sonData;

      this.loginBgColor = HexToRgb(loginBgColor);
      this.fontColor = loginFontColor;
      this.loginBgTransparency = loginBgOpcity;
    }
  },
  watch: {
    sonData() {
      this.fetchData();
    }
  }
};
</script>
