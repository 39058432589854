<template>
  <v-card flat>
    <v-card-text>
      <iframe
        ID="Frame1"
        :src="url"
        frameborder="no"
        scrolling="no"
        style="height: 250px;"
      >
      </iframe>
    </v-card-text>
  </v-card>
</template>

<script>
import { api_request } from "@/util/network";

export default {
  name: "PcOuth2EmbedCard",
  props: ["sonData", "curId", "curItem", "fromType"],
  data() {
    return {
      url:
        "https://passport.escience.cn/oauth2/authorize?response_type=code&client_id=77168&redirect_uri=http%3A%2F%2Fwebexp.qibebt.ac.cn%2Fapi%2Fsource%2Foauth2%2Ffinish&state=12345&theme=embed"
    };
  },
  created() {
    console.log("this.curId === ", this.curId);
    this.$http
      .get(`api/source/${this.curId}/start`)
      .delegateTo(api_request)
      .then(data => {
        console.log(data);
        this.url = data.content;
      })
      .catch(({ code }) => {
        throw `加载失败：${this.$t("api." + code)}`;
      });
  },
  methods: {},
  watch: {
    curItem() {
      this.fetchData();
    }
  }
};
</script>
<style lang="scss" scoped></style>
