var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.noticeDisplay === 'staticState' && _vm.showUseInstruction)?_c('div',{staticStyle:{"flex":"1"}},[_c('div',{staticClass:"white--text pa-6",style:({
      position: 'relative',
      minWidth: '300px',
      height: '500px',
      overflow: 'auto',
      borderRadius: '6px',
      marginRight: _vm.loginSite === 'left' ? '0' : '20px',
      marginLeft: _vm.loginSite === 'left' ? '20px' : '0',
      backgroundColor: ("rgba(" + _vm.loginBgColor + "," + _vm.loginBgTransparency + ")")
    })},[_c('div',{staticClass:"d-flex justify-center",style:({ color: _vm.fontColor, fontSize: '16px', fontWeight: '600' })},[_vm._v(" "+_vm._s(_vm.noticeTitle)+" ")]),_c('v-divider',{staticStyle:{"border-color":"#fff"}}),_c('div',{staticClass:"pa-4",style:({ color: _vm.fontColor, fontSize: '14px' }),attrs:{"id":"noticeContent"}})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }