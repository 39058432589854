var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center unselect",staticStyle:{"position":"absolute","top":"50px","left":"70px"}},[_c('img',{staticStyle:{"min-height":"30px","max-height":"60px","vertical-align":"middle"},attrs:{"src":(_vm.baseUrl + "/upload/" + _vm.logoUrl)}}),(_vm.logoDesc)?_c('span',{staticClass:"ml-4 ",style:({
        fontSize: '40px',
        borderLeft: ("4px solid " + _vm.fontColor),
        height: '40px',
        lineHeight: '40px',
        paddingLeft: '14px',
        color: _vm.fontColor
      })},[_vm._v(" "+_vm._s(_vm.logoDesc)+" ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }