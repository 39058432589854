<template>
  <div>
    <v-form :model="form" ref="form" @submit.native.prevent>
      <v-text-field
        class="text-body-1"
        v-model="userName"
        :label="usernamePlaceholder"
        outlined
        required
        :rules="rules.isFull"
        hide-details="auto"
      ></v-text-field>
      <v-text-field
        v-model="passWord"
        class="mt-2 text-body-1"
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show ? 'text' : 'password'"
        :label="passwordPlaceholder"
        @click:append="show = !show"
        outlined
        required
        :rules="rules.isFull"
        @keydown.enter="submit(curTabItem, userName, passWord)"
      ></v-text-field>
      <div class="mt-3 d-flex flex-column">
        <v-btn
          depressed
          class="white--text text-body-1"
          :style="{
            backgroundColor: `${highlightColor}`,
            height: '48px'
          }"
          @click="submit(curTabItem, userName, passWord)"
        >
          {{ loginSubmit }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import { encryptPassword } from "@/util/jwes";
import { saveLastAccount } from "@/util/fido_util";

export default {
  name: "Password",
  props: ["curTabItem", "sonData", "fromType"],
  data() {
    return {
      rules: {
        isFull: [v => !!v || "此项为必填项"]
      },
      form: {},
      show: false,
      userName: "",
      passWord: "",
      highlightColor: "#FF0000",
      usernamePlaceholder: "输入用户名",
      passwordPlaceholder: "输入密码",
      loginSubmit: "登录"
    };
  },
  created() {
    this.getData();
  },
  methods: {
    submit(curTabItem, userName, passWord) {
      if (this.$refs.form.validate()) {
        this.$http
          .get(`api/source/${curTabItem.id}/start`)
          .delegateTo(api_request)
          .then(({ exchange_key, challenge }) => {
            let loginUserNameKey = curTabItem.fields[0];
            let loginPwsKey = curTabItem.fields[1];

            let credentials = {
              [loginUserNameKey]: userName,
              [loginPwsKey]: passWord
            };
            if (credentials["password"] !== undefined) {
              credentials["password"] = encryptPassword(
                exchange_key,
                challenge,
                credentials["password"]
              );
            }

            this.$http
              .post(`api/source/${curTabItem.id}/finish`, credentials)
              .delegateTo(api_request)
              .then(data => {
                if (this.fromType === "WECHAT_RZKC") {
                  sessionStorage.removeItem("needBind");
                  this.$emit("showAccountEvent", {
                    showAccount: true,
                    accountInfo: data
                  });
                } else {
                  this.$store.commit("auth_success", data);
                  saveLastAccount(data);
                  if (data["return_url"]) {
                    window.location = data["return_url"];
                  } else {
                    this.$router.push({ path: "/" });
                  }
                  return "登录成功";
                }
              })
              .catch(({ code, message }) => {
                throw `登录失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
                  "api." + typeof message === "string"
                    ? message
                    : JSON.stringify(message)
                )}`;
              })
              .delegateTo(this.$snackbar.delegate);
          })
          .catch(({ code, message }) => {
            throw `获取失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
              "api." + typeof message === "string"
                ? message
                : JSON.stringify(message)
            )}`;
          })
          .delegateTo(this.$snackbar.delegateError);
      }
    },
    getData() {
      if (this.sonData) {
        let {
          usernamePlaceholder,
          passwordPlaceholder,
          loginSubmit,
          loginFrame: { loginHighLight }
        } = this.sonData;
        this.usernamePlaceholder = usernamePlaceholder
          ? usernamePlaceholder
          : "输入用户名";
        this.passwordPlaceholder = passwordPlaceholder
          ? passwordPlaceholder
          : "输入密码";
        this.loginSubmit = loginSubmit ? loginSubmit : "登录";
        this.highlightColor = loginHighLight;
      }
    }
  }
};
</script>

<style></style>
