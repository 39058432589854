<template>
  <div>
    <v-form :model="smsForm" ref="smsForm" class="py-4" @submit.native.prevent>
      <v-form :model="smsCodeForm" ref="smsCodeForm" @submit.native.prevent>
        <v-text-field
          class="text-body-1"
          v-model="userName"
          :label="usernamePlaceholder"
          outlined
          required
          :rules="rules.isFull"
          hide-details="auto"
        ></v-text-field>
        <v-text-field
          v-model="password"
          class="mt-2 text-body-1"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show ? 'text' : 'password'"
          :label="passwordPlaceholder"
          @click:append="show = !show"
          outlined
          required
          :rules="rules.isFull"
          hide-details="auto"
        ></v-text-field>
      </v-form>
      <div class="mt-2" style="position:relative;">
        <v-text-field
          v-model.trim="smsCode"
          class="text-body-1 d-flex flex-row align-center"
          :label="verifycodePlaceholder"
          @click:append="show = !show"
          outlined
          required
          :rules="rules.isRule"
          @keydown.enter="submitSMS(curTabItem)"
          maxlength="6"
        ></v-text-field>
        <v-btn
          depressed
          @click.stop="sendCode"
          class="white--text"
          :disabled="smsDisabled"
          style="z-index:0;position:absolute;top:1px;right:1px;border-radius:2px;width:100px;height:46px"
          :style="{
            backgroundColor: `${highlightColor}`
          }"
          >{{
            allTime === 60 || allTime === 0 ? "发送验证码" : allTime + "s"
          }}</v-btn
        >
      </div>
    </v-form>
    <div class="mt-3 d-flex flex-column">
      <v-btn
        depressed
        class="white--text text-body-1"
        :style="{
          backgroundColor: `${highlightColor}`,
          height: '48px'
        }"
        :disabled="isDisabled"
        @click="submitSMS(curTabItem)"
      >
        {{ loginSubmit }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import { encryptPassword } from "@/util/jwes";
import { saveLastAccount } from "@/util/fido_util";

export default {
  name: "FNU_LDAP",
  props: ["sonData", "curTabItem", "fromType"],
  data() {
    let isRule = value => {
      let reg = /^\d{6}$/;
      return value && reg.test(value);
    };
    return {
      rules: {
        isFull: [v => !!v || "此项为必填项"],
        isRule: [v => isRule(v) || "请输入正确的验证码"]
      },
      smsForm: {},
      show: false,
      smsCodeForm: {},
      userName: "",
      password: "",
      smsCode: "",
      loginSubmit: "",
      usernamePlaceholder: "输入用户名",
      passwordPlaceholder: "输入密码",
      verifycodePlaceholder: "输入验证码",
      noticeTitle: "",
      allTime: 60,
      highlightColor: "#FF0000",
      fontColor: "",
      verify_code: "",
      showCaptcha: false,
      captcha_image: "",
      captcha_challenge: "",
      timer: "",
      smsDisabled: false,
      isDisabled: true
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    // 获取验证码
    sendCode() {
      if (this.$refs.smsCodeForm.validate()) {
        this.smsDisabled = true;
        if (this.smsDisabled) {
          this.timer = setInterval(() => {
            if (this.allTime > 1) {
              this.allTime = this.allTime - 1;
            } else {
              clearInterval(this.timer);
              this.allTime = 60;
              this.smsDisabled = false;
            }
          }, 1000);
          return this.$http
            .get(
              `api/source/${this.curTabItem.id}/start?login_name=${this.userName}&password=${this.password}`
            )
            .delegateTo(api_request)
            .then(data => {
              if (data.type === "captcha_required") {
                let {
                  content: { captcha_challenge, captcha_image }
                } = data;
                this.captcha_challenge = captcha_challenge;
                this.captcha_image = captcha_image;
                this.showCaptcha = true;
              } else {
                let {
                  action: { mobile },
                  exchange_key,
                  challenge
                } = data;
                this.exchange_key = exchange_key;
                this.challenge = challenge;

                return `已发送至${mobile}`;
              }
            })
            .catch(({ code, message }) => {
              clearInterval(this.timer);
              this.allTime = 60;
              this.smsDisabled = false;
              if (message === "too frequently") {
                throw `发送验证码过于频繁，请等待一分钟后再试`;
              } else {
                throw `发送验证码失败：${this.$t("api." + code)}`;
              }
            })
            .delegateTo(this.$snackbar.delegateError);
        }
      }
    },
    // 图形验证码登录
    finishLogin(curItem) {
      if (this.$refs.smsForm.validate()) {
        if (!this.exchange_key && !this.challenge) {
          return this.$snackbar.showMessage({
            content: `请先获取验证码`,
            isError: true
          });
        } else {
          let loginUserNameKey = curItem.fields[0];
          let loginPwsKey = curItem.fields[1];
          let code = curItem.fields[2];
          let credentials;
          if (this.showCaptcha) {
            credentials = {
              [loginUserNameKey]: this.userName,
              [loginPwsKey]: this.password,
              [code]: this.smsCode,
              captcha_challenge: this.captcha_challenge,
              captcha_code: this.verify_code
            };
          } else {
            credentials = {
              [loginUserNameKey]: this.userName,
              [loginPwsKey]: this.password,
              [code]: this.smsCode
            };
          }

          if (credentials["password"] !== undefined) {
            credentials["password"] = encryptPassword(
              this.exchange_key,
              this.challenge,
              credentials["password"]
            );
          }
          this.$http
            .post(`api/source/${curItem.id}/finish`, credentials)
            .delegateTo(api_request)
            .then(data => {
              if (this.fromType === "WECHAT_RZKC") {
                sessionStorage.removeItem("needBind");
                this.$emit("showAccountEvent", {
                  showAccount: true,
                  accountInfo: data
                });
              } else {
                this.$store.commit("auth_success", data);
                saveLastAccount(data);
                if (data["return_url"]) {
                  window.location = data["return_url"];
                } else {
                  this.$router.push({ path: "/" });
                }
                return "登录成功";
              }
            })
            .catch(({ code }) => {
              throw `登录失败：${this.$t("api." + code)}`;
            })
            .delegateTo(this.$snackbar.delegateError);
        }
      }
    },
    redoStart() {
      if (this.$refs.smsCodeForm.validate()) {
        this.smsDisabled = true;
        if (this.smsDisabled) {
          let timer = setInterval(() => {
            if (this.allTime > 1) {
              this.allTime = this.allTime - 1;
            } else {
              clearInterval(timer);
              this.allTime = 60;
              this.smsDisabled = false;
            }
          }, 1000);
          return this.$http
            .get(
              `api/source/${this.curTabItem.id}/start?login_name=${this.userName}&captcha_challenge=${this.captcha_challenge}&captcha_code=${this.verify_code}`
            )
            .delegateTo(api_request)
            .then(({ exchange_key, challenge }) => {
              this.exchange_key = exchange_key;
              this.challenge = challenge;
            })
            .catch(({ code, message }) => {
              clearInterval(timer);
              this.allTime = 60;
              this.smsDisabled = false;
              if (code === "MobileNotFound") {
                throw "此用户无关联手机号";
              } else if (message === "too frequently") {
                throw `发送验证码过于频繁，请等待一分钟后再试`;
              } else {
                throw `发送验证码失败：${this.$t("api.sms." + code)}`;
              }
            })
            .delegateTo(this.$snackbar.delegateError);
        }
      }
    },
    doStart(curItem, loginName, verificationCode) {
      if (this.$refs.smsForm.validate()) {
        return this.$http
          .get(`api/source/${curItem.curId}/start?login_name=${loginName}`)
          .delegateTo(api_request)
          .then(({ type, content: { captcha_challenge, captcha_image } }) => {
            if (
              type === "captcha_required" &&
              captcha_challenge &&
              captcha_image
            ) {
              this.showCaptcha = true;
              this.captcha_image = captcha_image;
              localStorage.setItem("captcha_image", captcha_image);
            } else {
              this.smsLogin(this.curTabItem, loginName, verificationCode);
            }
          })
          .catch(({ code }) => {
            throw `发送验证码失败：${this.$t("api.sms." + code)}`;
          })
          .delegateTo(this.$snackbar.delegateError);
      }
    },
    submitSMS(curItem) {
      this.finishLogin(curItem);
    },
    // FNU_LDAP登录
    smsLogin(curItem, loginName, verificationCode) {
      if (this.$refs.smsForm.validate()) {
        if (!this.exchange_key && !this.challenge) {
          return this.$snackbar.showMessage({
            content: `请先获取验证码`,
            isError: true
          });
        } else {
          // 调取短信登录接口
          let loginUserNameKey = curItem.fields[0];
          let password = curItem.fields[1];
          let code = curItem.fields[2];
          let credentials = {
            [loginUserNameKey]: loginName,
            [password]: this.password,
            [code]: verificationCode
          };

          if (credentials["password"] !== undefined) {
            credentials["password"] = encryptPassword(
              this.exchange_key,
              this.challenge,
              credentials["password"]
            );
          }
          this.$http
            .post(`api/source/${curItem.id}/finish`, credentials)
            .delegateTo(api_request)
            .then(data => {
              if (this.fromType === "WECHAT_RZKC") {
                sessionStorage.removeItem("needBind");
                this.$emit("showAccountEvent", {
                  showAccount: true,
                  accountInfo: data
                });
              } else {
                this.$store.commit("auth_success", data);
                saveLastAccount(data);
                if (data["return_url"]) {
                  window.location = data["return_url"];
                } else {
                  this.$router.push({ path: "/" });
                }
                return "登录成功";
              }
            })
            .catch(({ code }) => {
              throw `登录失败：${this.$t("api." + code)}`;
            })
            .delegateTo(this.$snackbar.delegateError);
        }
      }
    },
    noticeEvent() {
      this.$emit("noticeChange", true);
    },
    fetchData() {
      if (this.sonData) {
        let {
          usernamePlaceholder,
          passwordPlaceholder,
          showUseInstruction,
          verifycodePlaceholder,
          loginSubmit,
          useInstructionTitle,
          noticeDisplay,
          loginFrame: { loginFontColor, loginHighLight }
        } = this.sonData;

        if (noticeDisplay === "staticState") {
          this.showUseInstruction = false;
        } else {
          this.showUseInstruction = showUseInstruction;
        }
        this.usernamePlaceholder = usernamePlaceholder
          ? usernamePlaceholder
          : "输入用户名";
        this.passwordPlaceholder = passwordPlaceholder
          ? passwordPlaceholder
          : "输入密码";
        this.verifycodePlaceholder = verifycodePlaceholder
          ? verifycodePlaceholder
          : "输入验证码";
        this.loginSubmit = loginSubmit ? loginSubmit : "登录";
        this.noticeTitle = useInstructionTitle;
        this.highlightColor = loginHighLight;
        this.fontColor = loginFontColor;
      }
    }
  },
  computed: {
    formFull() {
      const { userName, password, smsCode } = this;
      return { userName, password, smsCode };
    }
  },
  watch: {
    formFull(e) {
      if (e.userName && e.password && /^\d{6}$/.test(e.smsCode)) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    }
  }
};
</script>

<style></style>
