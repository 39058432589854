var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.noticeShow)?_c('div',[_c('div',{staticClass:"white--text pa-6",style:({
      position: _vm.loginSite === 'right' ? 'absolute' : 'relative',
      right: _vm.loginSite === 'right' ? '34%' : '0',
      width: '300px',
      minHeight: '200px',
      maxHeight: '500px',
      overflow: 'auto',
      borderRadius: '6px',
      marginRight: _vm.loginSite === 'left' ? '0' : '20px',
      marginLeft: _vm.loginSite === 'left' ? '20px' : '0',
      backgroundColor: ("rgba(" + _vm.loginBgColor + "," + _vm.loginBgTransparency + ")")
    })},[_c('div',{staticClass:"d-flex justify-space-between",style:({ color: _vm.fontColor, fontSize: '16px' })},[_vm._v(" "+_vm._s(_vm.noticeTitle)+" "),_c('v-btn',{staticStyle:{"position":"absolute","top":"10px","right":"10px"},attrs:{"icon":"","color":_vm.fontColor},on:{"click":_vm.noticeEvent}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{ref:"noticeContent",style:({ color: _vm.fontColor, fontSize: '14px' }),attrs:{"id":"noticeContent"}})])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }