<template>
  <div
    :style="{
      marginTop:
        showUseInstruction && noticeDisplay === 'staticState' ? '20px' : 0
    }"
  >
    <v-card class="transparent d-flex flex-column align-center" outlined tile>
      <div
        :style="{ marginTop: showUseInstruction ? '0px' : '20px' }"
        v-if="showLogo"
      >
        <v-img
          contain
          class="logo"
          min-width="60"
          max-width="160"
          min-height="40"
          :src="`${baseUrl}/upload/${logoUrl}`"
          :lazy-src="`data:image/png;base64,${CONSTS.lazyImg}`"
        ></v-img>
      </div>
      <div class="mt-3 text-h5" v-if="showSystemName">
        {{ system_name }}
      </div>
    </v-card>
  </div>
</template>

<script>
import { CONSTS } from "../utils/base";
export default {
  name: "MobileLogo",
  props: [
    "system_name",
    "configId",
    "sonData",
    "showUseInstruction",
    "noticeDisplay"
  ],
  data() {
    return {
      baseUrl: "",
      logoUrl: "",
      showLogo: false,
      showSystemName: false,
      CONSTS
    };
  },
  created() {
    this.baseUrl = process.env.VUE_APP_BASE_URL;
    this.logoUrl = this.configId
      ? `custom_login/${this.configId}/PC/logo.png`
      : "login/logo.png";
    this.fetchData();
  },
  methods: {
    fetchData() {
      if (this.sonData) {
        let { showLogo, showSystemName } = this.sonData;

        this.showLogo = showLogo ? true : false;
        this.showSystemName = showSystemName ? true : false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ #noticeContent {
  pre {
    white-space: pre-wrap; /*css-3*/
    white-space: -moz-pre-wrap; /*Mozilla,since1999*/
    white-space: -pre-wrap; /*Opera4-6*/
    white-space: -o-pre-wrap; /*Opera7*/
    word-wrap: break-word; /*InternetExplorer5.5+*/
  }
}
</style>
