<template>
  <div
    class="white unselect overflow-hidden d-flex flex-column align-center"
    style="width:100vw;height:100vh;"
  >
    <div style="margin-top:20vh">
      <v-icon color="#10AEFF" size="120">mdi-information</v-icon>
    </div>
    <div class="text-h6 mt-6">请使用{{ curClient }}打开链接</div>
  </div>
</template>

<script>
let typeNames = {
  WECHAT_WEBSITE: "微信网站",
  WECHAT_OFFICIAL: "微信公众号",
  WECHAT_RZKC: "微信RZKC",
  WECHAT_ENTERPRISE: "企业微信",
  DING_TALK: "钉钉",
  LARK: "飞书"
};
export default {
  name: "ErrorMobileLogin",
  props: ["errorType"],
  data() {
    return {
      curClient: null,
      typeNames
    };
  },
  created() {
    let type = this.errorType;
    if (type) {
      this.curClient = this.typeNames[type];
    }
  }
};
</script>

<style lang="less" scoped>
/deep/.v-input__slot {
  padding: 6px 12px !important;
  min-height: 48px !important;
}

/deep/.v-input__append-inner {
  margin-top: 8px !important;
}

/deep/.v-label {
  top: 10px !important;
}
</style>
