function isSetMobile(config) {
  let {
    tabs,
    otherLinks,
    wechatMethod,
    dingTalkMethod,
    larkMethod,
    wechatEnterpriseMethod
  } = config;
  if (
    tabs.length > 0 ||
    otherLinks.length > 0 ||
    wechatMethod != null ||
    dingTalkMethod != null ||
    larkMethod != null ||
    wechatEnterpriseMethod != null
  ) {
    return true;
  } else {
    return false;
  }
}

export { isSetMobile };
