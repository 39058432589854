<template>
  <div v-if="noticeShow">
    <div
      class="white--text pa-6"
      :style="{
        position: loginSite === 'right' ? 'absolute' : 'relative',
        right: loginSite === 'right' ? '34%' : '0',
        width: '300px',
        minHeight: '200px',
        maxHeight: '500px',
        overflow: 'auto',
        borderRadius: '6px',
        marginRight: loginSite === 'left' ? '0' : '20px',
        marginLeft: loginSite === 'left' ? '20px' : '0',
        backgroundColor: `rgba(${loginBgColor},${loginBgTransparency})`
      }"
    >
      <div
        class="d-flex justify-space-between"
        :style="{ color: fontColor, fontSize: '16px' }"
      >
        {{ noticeTitle }}
        <v-btn
          icon
          :color="fontColor"
          @click="noticeEvent"
          style="position:absolute;top:10px;right:10px"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div
        id="noticeContent"
        ref="noticeContent"
        :style="{ color: fontColor, fontSize: '14px' }"
      ></div>
    </div>
  </div>
</template>

<script>
import { HexToRgb } from "@/util/util";
export default {
  name: "Notice",
  props: ["sonData", "noticeShow"],
  data() {
    let isFull = value => {
      let isfull = value ? true : false;
      return isfull;
    };

    return {
      rules: {
        isFull: [v => isFull(v) || "此项为必填项"]
      },
      show: false,
      form: {},
      loginData: {},
      showUseInstruction: false,
      highlightColor: "",
      loginBgColor: "",
      loginBgTransparency: 0.5,
      fontColor: "",
      noticeTitle: "",
      noticeText: "",
      loginSite: ""
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    noticeEvent() {
      this.$emit("noticeChange", false);
    },
    fetchData() {
      let {
        showUseInstruction,
        useInstruction,
        useInstructionTitle,
        loginFrame: { loginFontColor, loginBgColor, loginBgOpcity, loginSite }
      } = this.sonData;

      this.showUseInstruction = showUseInstruction;
      this.noticeTitle = useInstructionTitle;
      if (useInstruction) {
        this.noticeText = useInstruction;
      }
      this.loginBgColor = HexToRgb(loginBgColor);
      this.loginBgTransparency = loginBgOpcity ? loginBgOpcity : 0.5;
      this.fontColor = loginFontColor;
      this.loginSite = loginSite;
    }
  },
  watch: {
    curItem() {
      this.fetchData();
    },
    noticeShow(v) {
      if (v === true) {
        this.$nextTick(() => {
          this.$refs.noticeContent.innerHTML = this.noticeText;
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ #noticeContent {
  pre {
    white-space: pre-wrap; /*css-3*/
    white-space: -moz-pre-wrap; /*Mozilla,since1999*/
    white-space: -pre-wrap; /*Opera4-6*/
    white-space: -o-pre-wrap; /*Opera7*/
    word-wrap: break-word; /*InternetExplorer5.5+*/
  }
}
</style>
