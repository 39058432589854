<template>
  <div>
    <div
      class="d-flex align-center unselect"
      style="position: absolute;top:50px;left:70px"
    >
      <img
        :src="`${baseUrl}/upload/${logoUrl}`"
        style="min-height:30px;max-height:60px;vertical-align:middle"
      />
      <span
        class="ml-4 "
        v-if="logoDesc"
        :style="{
          fontSize: '40px',
          borderLeft: `4px solid ${fontColor}`,
          height: '40px',
          lineHeight: '40px',
          paddingLeft: '14px',
          color: fontColor
        }"
      >
        {{ logoDesc }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PcLogo",
  props: ["logoDesc", "fontColor", "configId"],
  data() {
    return {
      baseUrl: "",
      logoUrl: ""
    };
  },
  created() {
    this.baseUrl = process.env.VUE_APP_BASE_URL;
    this.logoUrl = this.configId
      ? `custom_login/${this.configId}/PC/logo.png`
      : "login/logo.png";
  }
};
</script>

<style lang="less" scoped>
/deep/ #noticeContent {
  pre {
    white-space: pre-wrap; /*css-3*/
    white-space: -moz-pre-wrap; /*Mozilla,since1999*/
    white-space: -pre-wrap; /*Opera4-6*/
    white-space: -o-pre-wrap; /*Opera7*/
    word-wrap: break-word; /*InternetExplorer5.5+*/
  }
}
</style>
